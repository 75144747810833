import React from 'react';

function About() {
  return (
    <div>
      <h1>Acerca de</h1>
      <p>Esta es la página de información sobre la aplicación.</p>
    </div>
  );
}

export default About;
