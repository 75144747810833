import React, { useState, useEffect } from 'react';
import '../aspecto/cliente.css';
import '../aspecto/clientes_especiales.css'; // Import CSS for special clients

const App = () => {
  const [clients, setClients] = useState([]);
  const [searchId, setSearchId] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [tipoClientes, setTipoClientes] = useState([]);
  const [products, setProducts] = useState([]);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isClientListModalOpen, setIsClientListModalOpen] = useState(false);
  const [isCreateClientModalOpen, setIsCreateClientModalOpen] = useState(false);

  useEffect(() => {
    const fetchTipoClientes = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/apicliente/tipos`);
        const data = await response.json();
        if (response.ok) {
          setTipoClientes(data.recordset);
        }
      } catch (error) {
        console.error('Error al cargar los tipos de cliente:', error);
      }
    };

    fetchTipoClientes();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/apiproducto/data`);
        const data = await response.json();
        if (response.ok) {
          setProducts(data.sort((a, b) => a.ProNombreProducto.localeCompare(b.ProNombreProducto)));
        }
      } catch (error) {
        console.error('Error al obtener los productos:', error);
      }
    };

    fetchProducts();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/apicliente/dataall`);
      const data = await response.json();
      if (response.ok) {
        const allClients = data.recordset.map(client => ({
          id: client.IdCliente,
          codigoCliente: client.CodigoCliente,
          nombreApellido: client.NombreCliente,
          celular: client.Celular,
          nombreEmpresa: client.EmpresaCliente,
          documento: client.DocumentoCliente,
          localidad: client.LocalidadCliente,
          direccion: client.DireccionCliente,
          agencia: client.AgenciaCliente,
          mail: client.MailCliente,
          tipoCliente: client.TipoCliente,
        }));
        setClients(allClients);
      }
    } catch (error) {
      console.error('Error al obtener los clientes:', error);
    }
  };

  const handleSearch = async () => {
    console.log('Buscando cliente con código:', searchId);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/apicliente/data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ codigoCliente: searchId }),
      });
  
      const data = await response.json();
  
      // Verifica si la respuesta es válida
      if (response.ok && data.cliente) {
        const client = data.cliente; // Ajusta si el backend devuelve directamente un objeto llamado `cliente`
        console.log('Cliente encontrado:', client);
  
        setSelectedClient({
          id: client.IdCliente,
          codigoCliente: client.CodigoCliente,
          nombreApellido: client.NombreCliente,
          celular: client.Celular,
          nombreEmpresa: client.EmpresaCliente,
          documento: client.DocumentoCliente,
          localidad: client.LocalidadCliente,
          direccion: client.DireccionCliente,
          agencia: client.AgenciaCliente,
          mail: client.MailCliente,
          tipoCliente: client.TipoCliente,
        });
      } else {
        console.log('Cliente no encontrado.');
        setSelectedClient(null);
      }
    } catch (error) {
      console.error('Error al buscar el cliente:', error);
      setSelectedClient(null);
    }
  };  
    

  const handleEdit = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/apicliente/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          selectedClient
        ),
      });

      if (response.ok) {
        alert('Información actualizada correctamente');
        setIsEditing(false);
      } else {
        alert('Error al actualizar la información');
      }
    } catch (error) {
      console.error('Error al actualizar el cliente:', error);
    }
  };

  const handleOpenProductModal = () => {
    setIsProductModalOpen(true);
  };

  const handleOpenClientListModal = () => {
    fetchClients();
    setIsClientListModalOpen(true);
  };

  const handleOpenCreateClientModal = () => {
    setIsCreateClientModalOpen(true);
  };

  const handleCloseCreateClientModal = () => {
    setIsCreateClientModalOpen(false);
  };

  const handleClientCreated = () => {
    setIsCreateClientModalOpen(false);
    fetchClients(); // Refrescar la lista de clientes
  };

  return (
    <div className="CLIContainer">
      <h2 className="CLIContainer_h2">Buscar Cliente</h2>
      <input
        className="CLIContainer_searchInput"
        type="text"
        value={searchId}
        onChange={(e) => setSearchId(e.target.value)} // Actualización del estado
        placeholder="Ingrese el código del cliente"
      />
      <button className="CLIContainer_searchButton" onClick={handleSearch}>Buscar</button>
      <button className="CLIContainer_searchButton" onClick={handleOpenClientListModal}>Ver Todos los Clientes</button>
      <button className="CLIContainer_searchButton" onClick={handleOpenCreateClientModal}>Crear Cliente</button>

      {selectedClient ? (
        <div className="client-details">
          <h3 className="client-details-title">Detalles del Cliente</h3>
          <table className="client-details-table">
          <tbody>
            <tr>
              <td><strong>Codigo cliente:</strong></td>
              <td>
                {isEditing ? (
                  <input
                    type="text"
                    value={selectedClient.codigoCliente || ''}
                    onChange={e =>
                      setSelectedClient({ ...selectedClient, codigoCliente: e.target.value })
                    }
                  />
                ) : (
                  selectedClient.codigoCliente
                )}
              </td>
              <td><strong>Nombre y Apellido:</strong></td>
              <td>
                {isEditing ? (
                  <input
                    type="text"
                    value={selectedClient.nombreApellido || ''}
                    onChange={e =>
                      setSelectedClient({ ...selectedClient, nombreApellido: e.target.value })
                    }
                  />
                ) : (
                  selectedClient.nombreApellido
                )}
              </td>
            </tr>
            <tr>
              <td><strong>Celular:</strong></td>
              <td>
                {isEditing ? (
                  <input
                    type="text"
                    value={selectedClient.celular || ''}
                    onChange={e =>
                      setSelectedClient({ ...selectedClient, celular: e.target.value })
                    }
                  />
                ) : (
                  selectedClient.celular
                )}
              </td>
              <td><strong>Nombre de la Empresa:</strong></td>
              <td>
                {isEditing ? (
                  <input
                    type="text"
                    value={selectedClient.nombreEmpresa || ''}
                    onChange={e =>
                      setSelectedClient({ ...selectedClient, nombreEmpresa: e.target.value })
                    }
                  />
                ) : (
                  selectedClient.nombreEmpresa
                )}
              </td>
            </tr>
            <tr>
              <td><strong>Documento:</strong></td>
              <td>
                {isEditing ? (
                  <input
                    type="text"
                    value={selectedClient.documento || ''}
                    onChange={e =>
                      setSelectedClient({ ...selectedClient, documento: e.target.value })
                    }
                  />
                ) : (
                  selectedClient.documento
                )}
              </td>
              <td><strong>Localidad:</strong></td>
              <td>
                {isEditing ? (
                  <input
                    type="text"
                    value={selectedClient.localidad || ''}
                    onChange={e =>
                      setSelectedClient({ ...selectedClient, localidad: e.target.value })
                    }
                  />
                ) : (
                  selectedClient.localidad
                )}
              </td>
            </tr>
            <tr>              
              <td><strong>Dirección:</strong></td>
              <td>
                {isEditing ? (
                  <input
                    type="text"
                    value={selectedClient.direccion || ''}
                    onChange={e =>
                      setSelectedClient({ ...selectedClient, direccion: e.target.value })
                    }
                  />
                ) : (
                  selectedClient.direccion
                )}
              </td>
              <td><strong>Agencia:</strong></td>
              <td>
                {isEditing ? (
                  <input
                    type="text"
                    value={selectedClient.agencia || ''}
                    onChange={e =>
                      setSelectedClient({ ...selectedClient, agencia: e.target.value })
                    }
                  />
                ) : (
                  selectedClient.agencia
                )}
              </td>
            </tr>
            <tr>              
              <td><strong>Email:</strong></td>
              <td>
                {isEditing ? (
                  <input
                    type="email"
                    value={selectedClient.mail || ''}
                    onChange={e =>
                      setSelectedClient({ ...selectedClient, mail: e.target.value })
                    }
                  />
                ) : (
                  selectedClient.mail
                )}
               </td>
                <td><strong>Tipo de Cliente:</strong></td>
                <td>
                  {isEditing ? (
                    <select
                      name="TClIdTipoCliente"
                      value={selectedClient ? selectedClient.tipoCliente : ''} // El valor es el ID del tipo de cliente
                      onChange={(e) => setSelectedClient({ ...selectedClient, tipoCliente: e.target.value })} // Actualiza el ID seleccionado
                    >
                      <option value="">Seleccione un Tipo de Cliente</option>
                      {tipoClientes.map((tipo) => (
                        <option key={tipo.TClIdTipoCliente} value={tipo.TClDescripcion}>
                          {tipo.TClDescripcion} {/* Mostrar la descripción del tipo de cliente */}
                        </option>
                      ))}
                    </select>
                  ) : (
                    // Al no estar en modo de edición, mostramos la descripción del tipo de cliente
                    selectedClient.tipoCliente
                  )}
              </td>
             </tr>
          </tbody>
          </table>
          <div className="client-actions">
            {isEditing ? (
              <>
                <button className="save-button" onClick={handleEdit}>Guardar</button>
                <button className="cancel-button" onClick={() => setIsEditing(false)}>Cancelar</button>
              </>
            ) : (
              <>
                <button className="edit-button" onClick={() => setIsEditing(true)}>Editar</button>
                <button className="special-price-button" onClick={handleOpenProductModal}>Cargar Precio Especial</button>
              </>
            )}
          </div>
        </div>
      ) : (
        <p className="no-client">Cliente no encontrado</p>
      )}

      {isProductModalOpen && (
        <ProductModal
          closeModal={() => setIsProductModalOpen(false)}
          products={products}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
      )}

      {isClientListModalOpen && (
        <ClientListModal
          clients={clients}
          tipoClientes={tipoClientes}
          closeModal={() => setIsClientListModalOpen(false)}
        />
      )}

      {isCreateClientModalOpen && (
        <CreateClientForm
          tipoClientes={tipoClientes}
          closeModal={handleCloseCreateClientModal}
          onClientCreated={handleClientCreated}
        />
      )}
    </div>
  );
};

const CreateClientForm = ({ tipoClientes, closeModal, onClientCreated }) => {
  const [newClient, setNewClient] = useState({
    CliCodigoCliente: '',
    CliNombreApellido: '',
    CliCelular: '',
    CliNombreEmpresa: '',
    CliDocumento: '',
    CliLocalidad: '',
    CliDireccion: '',
    CliAgencia: '',
    CliMail: '',
    TClIdTipoCliente: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewClient((prevState) => ({ ...prevState, [name]: value }));
  };
  
  const handleSubmit = async () => {
    try {
      // Validar que los campos requeridos estén completos
      if (!newClient.CliCodigoCliente || !newClient.CliNombreApellido || !newClient.CliCelular) {
        alert('Por favor, completa todos los campos obligatorios');
        return;
      }
  
      // Enviar el cliente al backend
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/apicliente/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newClient),
      });
  
      const responseData = await response.json(); // Parsear la respuesta JSON
  
      if (response.ok) {
        alert('Cliente creado exitosamente');
        onClientCreated(); // Llama a la función para actualizar la vista
      } else if (response.status === 400) {
        // Manejo de errores específicos
        alert(`Error: ${responseData.error || 'Datos inválidos'}`);
      } else {
        alert('Error inesperado al crear el cliente');
      }
    } catch (error) {
      console.error('Error al crear cliente:', error);
      alert('Hubo un problema al crear el cliente. Revisa tu conexión o intenta más tarde.');
    }
  };
  

  return (
    <div className="clientes-modal show" onClick={(e) => { if (e.target.classList.contains('clientes-modal')) closeModal(); }}>
      <div className="clientes-modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="clientes-close" onClick={closeModal}>&times;</span>
        <h2>Crear Cliente</h2>
        <div className="form-container">
          <input type="text" name="CliCodigoCliente" placeholder="Código del Cliente" value={newClient.CliCodigoCliente} onChange={handleInputChange} />
          <input type="text" name="CliNombreApellido" placeholder="Nombre y Apellido" value={newClient.CliNombreApellido} onChange={handleInputChange} />
          <input type="text" name="CliCelular" placeholder="Celular" value={newClient.CliCelular} onChange={handleInputChange} />
          <input type="text" name="CliNombreEmpresa" placeholder="Nombre de la Empresa" value={newClient.CliNombreEmpresa} onChange={handleInputChange} />
          <input type="text" name="CliDocumento" placeholder="Documento" value={newClient.CliDocumento} onChange={handleInputChange} />
          <input type="text" name="CliLocalidad" placeholder="Localidad" value={newClient.CliLocalidad} onChange={handleInputChange} />
          <input type="text" name="CliDireccion" placeholder="Dirección" value={newClient.CliDireccion} onChange={handleInputChange} />
          <input type="text" name="CliAgencia" placeholder="Agencia" value={newClient.CliAgencia} onChange={handleInputChange} />
          <input type="email" name="CliMail" placeholder="Email" value={newClient.CliMail} onChange={handleInputChange} />
          <select name="TClIdTipoCliente" value={newClient.TClIdTipoCliente} onChange={handleInputChange}>
            <option value="">Seleccione un Tipo de Cliente</option>
            {tipoClientes.map(tipo => (
              <option key={tipo.TClIdTipoCliente} value={tipo.TClIdTipoCliente}>
                {tipo.TClDescripcion}
              </option>
            ))}
          </select>
          <button onClick={handleSubmit}>Crear Cliente</button>
        </div>
      </div>
    </div>
  );
};

const ProductModal = ({ closeModal, products, selectedProducts, setSelectedProducts }) => {
  // Mantener lógica previa
  return <div>Modal de productos</div>;
};

function ClientListModal({ clients, closeModal }) {
  return (
    <div className="clientes-modal show" onClick={(e) => { if (e.target.classList.contains('clientes-modal')) closeModal(); }}>
      <div className="clientes-modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="clientes-close" onClick={closeModal}>&times;</span>
        <h2>Lista de Clientes</h2>
        <table className="clientes-list-table">
          <thead>
            <tr>
              <th>ID Cliente</th>
              <th>Código Cliente</th>
              <th>Nombre</th>
              <th>Empresa</th>
              <th>Celular</th>
              <th>Documento</th>
              <th>Localidad</th>
              <th>Dirección</th>
              <th>Agencia</th>
              <th>Email</th>
              <th>Tipo Cliente</th> {/* Nueva columna */}
            </tr>
          </thead>
          <tbody>
            {clients.map((client, index) => (
              <tr key={index}>
                <td>{client.id}</td>
                <td>{client.codigoCliente}</td>
                <td>{client.nombreApellido}</td>
                <td>{client.nombreEmpresa}</td>
                <td>{client.celular}</td>
                <td>{client.documento}</td>
                <td>{client.localidad}</td>
                <td>{client.direccion}</td>
                <td>{client.agencia}</td>
                <td>{client.mail}</td>
                <td>{client.tipoCliente || 'Desconocido'}</td> {/* Muestra TipoCliente */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}


export default App;
